.app {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  background-color: #f2f2f2;
  font-family: 'Jost', Arial, Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
  overflow: hidden;
}

.table {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  /*    display: flex;
    gap: 32px;
    justify-content: center;
    flex-wrap: wrap;*/
  color: #212121;
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.table__double {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.table__triple {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.table__quatro {
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.table__five {
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  max-width: 1320px;
  gap: 16px;
}


.table__card {
  min-height: 200px;
  min-width: 260px;
  max-width: 360px;
  border-radius: 24px;
  flex: 1;
  background-color: #fff;
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;
  transition: .3s;
}

.table__card:hover {
  box-shadow: 0 8px 24px #dadadada;
  margin-top: -8px;
  margin-bottom: 8px;
}

.table__card-icon {
  width: 32px;
  height: 32px;
  margin: 4px auto 4px auto;
  opacity: 0.8;
}

.table__card-animatedIcon {
  width: 40px;
  height: 40px;
  margin: 0 auto 4px auto;
}

.table__card-header {
  background-color: #09cd;
  border-radius: 24px 24px 0 0;
  padding: 16px 32px;
  color: #fff;
}

.table__card-title {
  margin: 0;
  padding-bottom: 8px;
  font-size: 24px;
  font-weight: 500;
}

.table__card-about {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  opacity: 0.8;
}

.table__card-users {
  margin: 0;
  padding: 20px 20px 16px 20px;
  line-height: 24px;
}

.table__card-usersNumber {
  font-weight: 500;
  font-size: 23px;
  display: block;
  margin: 0;
  padding: 0;
}

.table__card-price {
  margin: 0;
  padding: 0;
  line-height: 25px;
}

.table__card-priceOld {
  margin: 0;
  padding: 0;
  text-decoration: line-through;
  font-size: 16px;
  opacity: 0.3;
}

.table__card-priceCurrent {
  margin: 0;
  padding: 2px;
  font-size: 25px;
  color: #09c;
}

.table__card-priceCurrentNumber {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.table__card-priceCurrentDiscount {
  display: block;
  margin: 0 4px;
  padding: 1px 8px;
  background-color: #9c0;
  transform: rotate(-4deg);
  border-radius: 4px;
  color: #fff;
  font-size: 21px;
  font-weight: 500;
  display: inline-block;
}

.table__card-priceAbout {
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  color: #09c;
}

.table__card-volume {
  margin: 0;
  padding: 20px 20px 16px 20px;
  font-weight: 500;
  font-size: 23px;
}

.table__card-list {
  margin: 0;
  padding: 0 20px 20px 20px;
  list-style-type: none;
  text-align: left;
}

.table__card-listItem {
  display: inline-block;
  margin: 0;
  padding: 8px 0 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.table__card-disabled {
  opacity: 0.4;
  filter: grayscale();
}

.table__card-listItemLevelGreen {
  border: 2px solid #9c0 !important;
}

.table__card-listItemLevelGreen:after {
  background-color: #9c0 !important;
}


.table__card-listItemLevel {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 12px;
  vertical-align: middle;
  margin-left: 8px;
  border-radius: 6px;
  border: 2px solid #09c;
  box-sizing: border-box;
  padding: 2px;
  opacity: 0.8;
}

.table__card-listItemLevel::after {
  content: '';
  position: absolute;
  border-radius: 3px;
  background-color: #09c;
  height: 4px;
  box-sizing: border-box;
}

.table__card-listItemLevelLow::after {
  width: 10px;
}

.table__card-listItemLevelMid::after {
  width: 19px;
}

.table__card-listItemLevelHigh::after {
  width: 28px;
}

.table__card-listItemLevelExists {
  border: 2px solid #9c0;
  width: 12px;
}

.table__card-listItemLevelExists::after {
  background-color: #9c0;
  width: 4px;
}

.table__card-sublist {
  margin: 0 0 0 24px;
  padding: 0;
  list-style-type: none;
}

.table__card-sublistItem {
  margin: 0;
  padding: 0;
  font-size: 16px;
}


.page__text {
  font-size: 22pt;
  line-height: 52px;
  display: inline;
  color: #323232;
  display: inline-block;
  margin: 0;
}

.page__text-highlight {
  border-radius: 8px;
  background-color: #ffb85032;
  padding: 0 8px;
}

.page__content-center {
  text-align: center;
}

.selector {
  margin: -152px auto 80px auto;
}

.selector__blog {
  margin: -96px auto 72px auto;
}

.caption {
  margin: -96px auto 64px auto;
}

.caption__record {
  margin: -192px auto 112px auto;
}

.caption__text {
  color: #fff;
  font-size: 21px;
  margin: 0;
  padding: 8px 24px;
}

.selector__caption {
  color: #fff;
  font-size: 17px;
  margin: 0;
  padding: 8px 24px;
}

.selector__container {
  background-color: #fffa;
  padding: 2px;
  border-radius: 16px;
  display: flex;
  max-width: 420px;
  gap: 2px;
  margin: 0 auto;
  font-size: 19px;
}

.selector__button {
  flex: 1;
  border: none;
  padding: 6px 8px;
  border-radius: 16px;
  color: #212121;
  background: none;
  cursor: pointer;
  transition: .2s;
}

.selector__button-selected {
  background-color: #09c;
  color: #fff;
  box-shadow: 0 2px 12px #dadadada;
}

.selector__button-green {
  background-color: #9c0;
  color: #fff;
  box-shadow: 0 2px 12px #dadadada;
}


.selector__button-discount {
  display: block;
  margin: 0 4px;
  padding: 1px 6px;
  background-color: #9c0;
  transform: rotate(-4deg);
  border-radius: 2px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}

.promo__banner {
  border-radius: 24px;
  width: 100%;
  margin-bottom: 16px;
}

.selector__promo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 12px 16px 24px 16px;
}

.selector__promo-container {
  width: 100%;
  max-width: 560px;
  margin-top: -36px;
}

@media (max-width: 562px) {
  .selector__container {
    font-size: 15px;
  }

  .selector__button-discount {
    font-size: 13px;
    margin: 0 0 0 4px;
    padding: 1px 4px;
  }

  .promo__banner {
    display: none;
  }
}

.minicards {
  position: relative;
  width: 100%;
  max-width: 1080px;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 72px;
  min-height: 320px;
  padding-bottom: 24px;
}

.cardLink {
  flex: 1;
  text-decoration: none;
  color: inherit;
}

.content__text {
  font-size: 22pt;
  line-height: 52px;
  display: inline;
  color: #323232;
  display: inline-block;
  margin: 0;
}

.content__caption {
  font-weight: 500;
  font-size: 20pt;
  padding-bottom: 16px;
  margin: 0;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content__double {
  line-height: 36px;
  font-size: 22px;
}

.content__highlight {
  border-radius: 8px;
  background-color: #ffb85032;
  padding: 0 8px;
}

.content__annotation {
  opacity: 0.8;
  font-size: 14px;
  font-weight: 500;
}

.content__simpleLink {
  text-decoration: none;
  color: inherit;
  transition: .2s;
}

.content__simpleLink:hover {
  opacity: 0.7;
}

.content__contacts {
  line-height: 28px;
  font-size: 22px;
  padding-bottom: 12px;
}

.content__center {
  text-align: center;
}

.content__narrow {
  max-width: 1080px;
  margin: 0 auto;
}

.content__padding {
  padding: 0 0 80px 0;
}

.orderForm__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background-color: #01010177;
  padding: 40px 24px;
  box-sizing: border-box;
  transition: .2s;
}

.orderForm__close {
  width: 16px;
  height: 16px;
  padding: 4px;
  background-image: url('../../images/closeIcon.png');
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  margin: 0;
  cursor: pointer;
  transition: .2s;
}

.orderForm__close:hover {
  opacity: 1;
}

.orderForm {
  min-width: 320px;
  min-height: 320px;
  max-width: 640px;
  border-radius: 24px;
  background-color: #fffd;
  backdrop-filter: blur(24px);
  padding: 24px 40px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}


.orderFormVisible {
  opacity: 1;
}

.orderFormHidden {
  opacity: 0;
  z-index: -1;
}

@media (max-width: 876px) {
  .orderForm__container {
    padding: 20px 16px;
  }

  .orderForm {
    max-width: 100%;
    border-radius: 24px;
    padding: 24px 40px;
  }
}

.admin_page {
  height: 100vh;
  background-color: #ddd;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: 'Jost', Arial, Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
}

.admin_page_form {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin_page_header {
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 24px;
  box-sizing: border-box;
  background-color: #333;
}

.admin_page_logo {
  line-height: 24px;
  font-size: 24pt;
  font-weight: 700;
  margin-top: -4px;
  color: #fff;
  font-family: "Jost";
  text-decoration: none;
}

.admin_page_login {
  font-weight: 400;
  font-size: 16px;
}

.admin_page_text {
  padding: 0;
  margin: 0;
  color: #fff;
}

.admin_page_input {
  border-radius: 12px;
  border: none;
  margin: 0;
  background-color: #fff;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 15px;
  max-width: 240px;
}

.admin_page_image {
  width: 100%;
}

.admin_page_row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.admin_page_workspace {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.admin_page_list {
  width: 240px;
  background-color: #f8f9fa;
  padding: 12px;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.admin_page_item {
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border: 1px solid #09c0;
}

.admin_page_item:hover {
  background-color: #09c1;
}

.admin_page_item__selected {
  border: 1px solid #09c;
}

.admin_page_item__selected:hover {}

.admin_page_item_title {
  font-weight: 600;
  font-size: 15px;
  color: #222;
}

.admin_page_item_date {
  font-weight: 500;
  font-size: 16px;
  color: #aaa;
  font-size: 12px;
}


.admin_page_item_type {
  font-weight: 500;
  font-size: 16px;
  color: #aaa;
  font-size: 12px;
  text-transform: uppercase;
}

.admin_page_item_type__news {
  color: #09c;
}

.admin_page_item_type__article {
  color: #9c0;
}

.admin_page_item_type__promo {
  color: #c09;
}

.admin_page_editor {
  flex: 1;
  overflow-y: scroll;
  background-color: #fff;
}

.admin_page_line {
  display: flex;
  width: 100%;
  gap: 12px;
}

.admin_page_line button {
  flex: 1;
}

.records {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-content: center;
  justify-content: center;
}

.record-link {
  width: 100%;
  max-width: 720px;
  flex: 1;
  text-decoration: none;
  color: inherit;
  display: block;
  margin: 0 auto;
}

.record-no {
  width: 100%;
  max-width: 720px;
  text-align: center;
  flex: 1;
  display: block;
  margin: 0 auto;
  padding: 24px;
}

.record-renderer a {
  color: #09c;
}

.simple-image__container {
  margin: 40px auto;
  width: 100%;
}

.simple-image {
  width: 100%;
  max-width: 720px;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
}

.simple-image__caption {
  text-align: center;
  display: block;
  margin: 16px;
}

.contacts_content {
  display: flex;
  flex-flow: column-reverse;
  padding: 0 !important;
  gap: 40px;
}
