.main {
  position: relative;
  z-index: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  margin-top: -88px;
}

.main__banner {
  width: 100%;
  height: 100vh;
  min-height: 800px;
}

.main__banner-back {
  position: fixed;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url('../../images/sub.jpg');
  background-size: cover;
  background-position: center;
  background-color: #222;
}

.main__banner-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1080px;
  box-sizing: border-box;
  padding: 24px;
  margin: 0 auto;
}

.main__banner-image {
  flex: 1;
  height: 80%;
  max-height: 520px;
  background-image: url('../../images/rocket.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.main__banner-caption {
  flex: 1;
  color: #fff;
  font-size: 48pt;
  font-weight: 400;
  line-height: 72px;
  text-align: center;
}

.main__banner-subtitle {
    font-size: 22pt;
    font-weight: 400;
    line-height: 36px;
    margin: 0;
}

.main__banner-title {
    font-size: 48pt;
    font-weight: 400;
    line-height: 72px;
    margin: 0;
}

.main__wave {
  background:
    linear-gradient(to right, rgb(255, 255, 255), chocolate);
  height: 0px;
  position: relative;
  transform: rotate(180deg);
}

.main__wave::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  background-image:
    radial-gradient(circle at 10px -5px, #f2f2f2 12px, transparent 13px);
}

.main__block {
  width: 100%;
  padding: 24px 24px;
  box-sizing: border-box;
  background-color: #f2f2f2;
}

.main__content {
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  gap: 56px;
  padding-bottom: 80px;
}

.main__content-text {
  font-size: 14pt;
  line-height: 32px;
}

.main__cards {
  flex: 1;
  display: flex;
  gap: 56px;
}


.main__cards-link {
  text-decoration: none;
  color: inherit;
}

.main__card {
  transition: .3s;
  flex: 1;
  background-color: #0099ff;
  color: #fff;
  border-radius: 12px;
  min-height: 320px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.main__card:hover {
  box-shadow: 0 1px 48px #3337;
}

.main__card:hover > .main__card-image {
  height: 210px;
}

.main__card-title {
  font-size: 20pt;
  font-weight: 500;
  margin: 0;
}

.main__minicard-image {
  transition: .5s;
  object-fit: contain;
  object-position: right;
  height: 160px;
  margin-right: -64px;
  margin-top: -64px;
}

.main__card-image {
  transition: .5s;
  object-fit: contain;
  object-position: right;
  height: 180px;
  margin-right: -56px;
  margin-top: -64px;
}

.main__card-text {
  flex: 1;
  font-size: 14pt;
  font-weight: 300;
  margin: 12px 0;
}

.main__minicards {
  flex: 2;
  display: flex;
  gap: 56px;
}

.main__minicard {
  transition: .3s;
  flex: 1;
  background-color: #deb8877a;
  border-radius: 12px;
  padding: 32px;
  min-height: 240px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}


.main__minicard:hover {
  box-shadow: 0 10px 48px #3337;
}

.main__minicard:hover > .main__minicard-image {
  height: 190px;
}