.pricingCard {
    position: relative;
    min-height: 200px;
    min-width: 240px; /*270px;*/
    max-width: 460px;
    border-radius: 24px;
    flex: 1;
    font-size: 18px;
    line-height: 28px;
    cursor: pointer;
    transition: .3s;
    color: #212121;
    background-color: #fff7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
}

/*
.pricingCard_highlighted {
    border-top: 3px solid #7b63c5;
    position: relative;
}

.pricingCard_highlighted::before {
    content: 'самая популярная';
    position: absolute;
    width: 100%;
    text-align: center;
    top: -28px;
    font-size: 12px;
    font-weight: 600;
    color: #7b63c5;
    text-transform: uppercase;
}
*/

    .pricingCard:hover {
        /*box-shadow: 0 8px 24px #dadadada;*/
        margin-top: -8px;
        margin-bottom: 8px;
        /*background-color: #ffc000;*/
        background-color: #fffa;
    }

        .pricingCard:hover > .pricingCard-block {
            background-color: #fff;
            box-shadow: 0 8px 16px #dadada77;
            margin: -12px;
            padding: 36px;
            border-radius: 24px;
        }

    .pricingCard > div:last-child {
        border-radius: 0 0 24px 24px;
    }

.pricingCard-icon {
    width: 32px;
    height: 32px;
    margin: 4px auto 4px auto;
    opacity: 0.8;
}

.pricingCard-animatedIcon {
    width: 40px;
}

.pricingCard-header {
    /*background-color: #09cd;*/
    border-radius: 24px 24px 0 0;
    padding: 24px;
    /*color: #fff;*/
}

.pricingCard-title {
    margin: 0;
    padding-bottom: 8px;
    font-size: 21px;
    font-weight: 600;
}

.pricingCard-about {
    margin: 0;
    padding: 8px 0 12px 0;
    font-size: 17px;
    line-height: 20px;
    opacity: 0.7;
    min-height: 80px;
}

.pricingCard-usersContainer {
    gap: 6px;
    display: flex;
    align-items: center;
    padding: 20px 0 0 0;
}


.pricingCard-users {
    flex: 1;
    margin: 0;
    padding: 0;
    line-height: 21px;
}

.pricingCard-usersNumbers {
    display: flex;
    gap: 8px;
    margin: 0 -8px 0 -8px;
    flex-wrap: wrap;
}

.pricingCard-usersNumber {
    font-weight: 500;
    font-size: 21px;
    display: block;
    margin: 0;
    padding: 4px 8px;
    border-radius: 128px;
    transition: .2s;
}

.pricingCard-usersNumber-selector {
    font-weight: 500;
    padding: 0;
    margin: 0 8px;
    border-radius: 6px;
}

.pricingCard-usersNumber__nan {
    font-size: 20px;
}

.pricingCard-usersNumberSelectable {
    cursor: pointer;
}

    .pricingCard-usersNumberSelectable:hover {
        background-color: #dadada33;
    }

.pricingCard-usersNumberSelected {
    background-color: #ffd80077;
}

    .pricingCard-usersNumberSelected:hover {
        background-color: #ffd80077;
    }

.pricingCard-price {
    margin: 0;
    padding: 0;
    line-height: 25px;
}

.pricingCard-priceOld {
    position: relative;
    margin: 0;
    padding: 0 0 15px 0;
    text-decoration: line-through;
    font-size: 21px;
    opacity: 0.3;
}

.pricingCard-priceCurrent {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 22px;
    color: #09c;
}

.pricingCard-priceCurrentGreen {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 22px;
    color: #9c0;
}

.pricingCard-priceCurrentNumber {
    font-size: 36px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.pricingCard-priceCurrentText {
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.pricingCard-priceCurrentDiscount {
    position: absolute;
    top: -20px;
    margin: 0 4px 0 -28px;
    padding: 1px 6px;
    background-color: #9c0;
    transform: rotate(-4deg);
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
}

.pricingCard-priceAbout {
    margin: 0;
    padding: 0;
    /*font-weight: 500;*/
    font-size: 15px;
    color: #777;
    opacity: 0.8;
}

.pricingCard-block {
    background-color: #fffd;
    /*border-radius: 24px;*/
    padding: 24px;
    transition: .2s;
}


.pricingCard-volume {
    margin: 0;
    padding: 20px 0 0 0;
    font-weight: 500;
    font-size: 21px;
}

.pricingCard-list {
    margin: 0;
    padding: 0 24px 24px 24px;
    list-style-type: none;
    text-align: left;
}

.pricingCard-listItem {
    display: inline-block;
    margin: 0;
    padding: 8px 0 0 0;
    font-weight: 500;
}

.pricingCard-listItemLevel {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 12px;
    vertical-align: middle;
    margin-left: 8px;
    border-radius: 6px;
    border: 2px solid #09c;
    box-sizing: border-box;
    padding: 2px;
    opacity: 0.8;
}

    .pricingCard-listItemLevel::after {
        content: '';
        position: absolute;
        border-radius: 3px;
        background-color: #09c;
        height: 4px;
        box-sizing: border-box;
    }

.pricingCard-listItemLevelLow::after {
    width: 12px;
}

.pricingCard-listItemLevelMid::after {
    width: 20px;
}

.pricingCard-listItemLevelHigh::after {
    width: 28px;
}

.pricingCard-sublist {
    margin: 0 0 0 24px;
    padding: 0;
    list-style-type: none;
}

.pricingCard-sublistItem {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.pricingCard-list {
    margin: 0;
    padding: 24px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.pricingCard-buttonContainer {
    text-align: center;
    margin: 24px 0 8px 0;
    padding: 0 32px;
    box-sizing: border-box;
}

.pricingCard-button {
    display: inline-block;
    margin: 0 auto;
    padding: 6px 8px;
    box-sizing: border-box;
    width: 100%;
    color: #fff;
    background-color: #09c;
    border-radius: 12px;
    cursor: pointer;
    opacity: .9;
    font-weight: 500;
    font-size: 17px;
    transition: .2s;
}

.pricingCard-button:hover {
    opacity: 1;
}

.pricingCard-buttonGreen {
    display: inline-block;
    margin: 0 auto;
    padding: 6px 8px;
    box-sizing: border-box;
    width: 100%;
    color: #fff;
    background-color: #9c0;
    border-radius: 12px;
    cursor: pointer;
    opacity: 0.9;
    font-weight: 500;
    font-size: 17px;
    transition: .2s;
}

    .pricingCard-buttonGreen:hover {
        opacity: 1;
    }

@media (max-width: 562px) {
    .pricingCard-about {
        min-height: 56px;
    }
}