.navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.navigation__links {
  position: relative;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 17px;
}

.navigation__link {
  padding-left: 28px;
}

@media (max-width: 876px) {
    .navigation {
        display: none;
    }
}