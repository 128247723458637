.page {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    margin-top: -88px;
    flex: 1;
}

.page__banner {
    position: relative;
    width: 100%;
    height: 480px;
    background-size: cover;
    background-position: center;
    background-color: rgb(0, 44, 139);
    background-attachment: fixed;
}

    .page__banner::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: #02020222;
        z-index: -1;
    }

.page__banner-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 24px;
    margin: 0 auto;
}

.page__banner-image {
    display: none;
    flex: 1;
    height: 80%;
    max-height: 300px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.page__banner-caption {
    text-align: center;
    flex: 1;
    color: #fff;
}

.page__banner-subtitle {
    font-size: 22pt;
    font-weight: 400;
    line-height: 36px;
    margin: 0;
}

.page__banner-title {
    font-size: 48pt;
    font-weight: 400;
    line-height: 72px;
    margin: 0;
}

.page__wave {
    background: linear-gradient(to right, rgb(255, 255, 255), chocolate);
    height: 0px;
    position: relative;
    transform: rotate(180deg);
}

    .page__wave::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        right: 0;
        background-repeat: repeat;
        height: 10px;
        background-size: 20px 20px;
        background-image: radial-gradient(circle at 10px -5px, #f2f2f2 12px, transparent 13px);
    }

.page__content {
    align-items: center;
    width: 100%;
    max-width: 1320px;
    box-sizing: border-box;
    padding: 24px 24px 80px 24px;
    margin: 0 auto;
    font-size: 14pt;
    line-height: 32px;
}

@media (max-width: 876px) {
    .page__banner-title {
        font-size: 42pt;
        line-height: 64px;
    }
}
