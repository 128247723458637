.calc {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    margin: 28px 0;
    background-color: #fffd;
}

.calcFeedback {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    margin: 0;
    padding: 16px 20px 20px 20px;
    background-color: #fffd;
    box-sizing: border-box;
}

.calcCaption {
    margin: 0;
    padding: 0 4px;
    line-height: 28px;
    font-size: 19px;
    color: #222;
}

.calcError {
    margin: 0;
    padding: 4px;
    line-height: 24px;
    font-size: 16px;
    color: #ff4237;
}

.calcForm {
    display: flex;
    margin: 12px 0 0 0;
    padding: 0;
    gap: 20px;
    box-sizing: border-box;
}

.calcInput {
    border-radius: 12px;
    border: none;
    margin: 0;
    background-color: #fffd;
    flex: 1;
    width: 0;
    font-size: 17px;
    padding: 12px;
    width: 100%;
    font-weight: 500;
    font-size: 17px;
    transition: .2s;
    max-width: 240px;
}

.calcButton {
    border: none;
    margin: 0 0 0 32px;
    padding: 6px 16px;
    box-sizing: border-box;
    color: #fff;
    background-color: #09c;
    border-radius: 12px;
    cursor: pointer;
    opacity: 0.9;
    font-weight: 500;
    font-size: 17px;
    transition: .2s;
    text-align: center;
    cursor: pointer;
    flex: 1;
}

    .calcButton:hover {
        opacity: 1;
    }

.calcButtonDisabled {
    cursor: default;
    pointer-events: none;
    background-color: #949494;
    opacity: .8;
}

.calcSent {
    max-width: 112px;
    max-height: 112px;
    opacity: .9;
    margin: 0 auto 8px auto;
}

@media (max-width: 876px) {

    .calcForm {
        flex-direction: column;
        gap: 12px;
        align-items: center;
    }

    .calcButton {
        flex: 1;
        margin: 0;
        padding: 12px 24px;
    }

    .calcInput {
        max-width: 260px;
        margin: 0 16px;
    }

    .calcCaption {
        text-align: center;
        margin-bottom: 8px;
    }

    .calcError {
        text-align: center;
        padding: 0 4px;
    }
}
