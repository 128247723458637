.footer__container {
    width: 100%;
    background-color: #323232;
    z-index: 0;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 28px 24px;
    margin: 0 auto;
}

.footer__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
}

.footer__info {
    color: #fff;
    opacity: 0.3;
    font-size: 13px;
    text-align: right;
}

.footer__logo {
    line-height: 24px;
    font-size: 24pt;
    font-weight: 700;
}


@media (max-width: 562px) {
    .footer {
        padding: 40px 24px;
    }
}
