.button {
  align-items: center;
  border-radius: var(--button-border-radius);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  padding: 6px 8px;
  text-align: center;
  transition: var(--common-transition);
}

.button:disabled {
  background-color: var(--bg-button-disabled);
}

.blueButton {
  background-color: var(--bg-button-blue);
}

.blueButton:hover {
  background-color: var(--bg-button-blue-hover);
}

.greenButton {
  background-color: var(--bg-button-green);
}

.greenButton:hover {
  background-color: var(--bg-button-green-hover);
}

.blueOutlineButton {
  background: var(--bg-button-none);
  border: 1px solid var(--bg-button-blue);
  color: var(--text-link-blue);
}

.blueOutlineButton:hover {
  border: 1px solid var(--bg-button-blue-hover);
  color: var(--text-link-blue-hover);
}