.bottomNavigation {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    bottom: 24px;
    padding: 0 12px;
    box-sizing: border-box;
    opacity: 1;
    margin-bottom: -88px;
    transition: .2s;
    transition-timing-function: ease-out;
}

.bottomNavigationVisible {
  opacity: 1;
  margin-bottom: 0;
}

.navigation__links {
  position: relative;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 12px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 12pt;
  border-radius: 16px;
  background-color: #ffffff88;
  backdrop-filter: blur(24px);
  box-shadow: 0 1px 1px #ddde;
  transition: .2s;
}

.navigation__link {
    padding: 0 12px;
    transition: .2s;
}

.navigation__icon {
    width: 18px;
    height: 18px;
}

.navigation__navlink {
  text-decoration: none;
  color: #000;
  transition: .2s;
}

.navigation__navlink:hover {
  opacity: 0.6;
}

.navigation__navlink-highlighted {
  border-radius: 12px;
  background-color: #fff1;
  padding: 2px 12px;
}

.navigation__navlink-highlighted:hover {
  background-color: #09c;
  color: #fff;
}

.navigation__card {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 48px;
  margin-left: -23px;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: #ffffffdd;
  backdrop-filter: blur(24px);
  box-shadow: 0 1px 1px #dddd;
}

.navigation__card-navlink {
  color: #111;
  margin: 6px 0;
}

.navigation__container {
    position: relative;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 876px) {
    .bottomNavigation {
        padding: 0 12px;
    }

    .navigation__links {
        padding: 8px;
        font-size: 12pt;
    }

    .navigation__icon {
        width: 16px;
        height: 16px;
    }

    .navigation__link {
        padding: 0 12px;
    }

    .navigation__card {
        padding: 8px;
    }
}

@media (max-width: 562px) {
    .bottomNavigation {
        padding: 0 10px;
    }

    .navigation__links {
        padding: 6px 8px;
    }

    .navigation__icon {
        width: 15px;
        height: 15px;
    }

    .navigation__link {
        padding: 4px 10px;
    }

    .navigation__card {
        padding: 8px;
    }

    .navigation__links {
        flex-direction: column;
    }
}
