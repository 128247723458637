.b24articles {
  align-content: center;
  display: flex;
  flex-direction: column;
  gap: 42px;
  position: relative;
}

.article {
  align-items: center;
  color: var(--text-color-dark);
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 32px 40px;
  position: relative;
}

.b24article {
  background-color: var(--bg-color-blue);
  border-radius: 24px;
  color: var(--text-color-light);
  overflow: visible;
  text-decoration: none;
  transition: ease-out .2s;
}

.b24article:hover {
  background-color: var(--bg-color-blue-hover);
  box-shadow: 0 12px 32px var(--common-shadow-color);
  margin-bottom: 8px;
  margin-top: -8px;
  overflow: visible;
}

.article__description {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.article__title {
  font-size: 24pt;
  font-weight: 500;
  margin: 0;
  padding-bottom: 16px;
}

.article__subtitle {
  color: var(--text-color-green);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  padding-bottom: 12px;
  text-transform: uppercase;
}

.article__text {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 22px 0;
}

.article__paragraph {
  font-size: 14pt;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding-bottom: 16px;
}

.article__link {
  color: var(--text-link-blue);
  cursor: pointer;
  flex: 1;
  text-decoration: none;
}

.article__link:hover {
  color: var(--text-link-blue-hover);
  text-decoration: underline;
}

.article__link_type_button:hover {
  text-decoration: none;
}

.article__image {
  border-radius: 10px;
  flex: 1 1;
  max-width: 504px;
  object-fit: contain;
  width: 100%;
}

.article__button_green {
  align-items: center;
  background-color: #9c0;
  border-radius: 12px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 17px;
  font-weight: 500;
  justify-content: center;
  margin: 0 auto;
  opacity: 0.9;
  padding: 6px 8px;
  text-align: center;
  text-decoration: none;
  transition: .2s;
  width: 100%;
}

.article__button_green:hover {
  opacity: 1;
}

.article__buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.article__button {
  display: inline-flex;
  flex: 1;
  width: 100%;
}

@media (max-width: 876px) {
  .article__image {
    order: 1;
  }

  .article__description {
    order: 2;
  }

  .article__link {
    flex: auto;
    width: 100%;
  }
}
