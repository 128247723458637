.header {
    display: flex;
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 32px;
    margin: 0 auto;
    z-index: 1;
}

.header__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
}

.header__phones {
    display: flex;
    margin-left: 20px;
    gap: 16px;
}


@media (max-width: 876px) {

    .header__phones {
        flex-direction: column;
        gap: 4px;
        margin-left: auto;
    }
}

.header__phone {

}

.header__logo {
    line-height: 24px;
    font-size: 24pt;
    font-weight: 700;
    margin-top: -4px;
}

.header__icon {
    width: 28px;
    margin: 0 8px 0 0;
    filter: brightness(0) invert(1);
}

@media (max-width: 876px) {
    .header {
        padding: 16px 32px;
    }
}