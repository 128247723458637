:root {
  --background: #f2f2f2;

  --bg-color-blue: #0099cc;
  --bg-color-blue-hover: #0099cce6;

  --button-border-radius: 12px;

  --text-color-dark: #212121;
  --text-color-light: #fff;
  --text-color-blue: #09c;
  --text-color-green: #9c0;

  --text-link-blue: #0099cce6;
  --text-link-blue-hover: #0099cc;

  --bg-button-green: #99cc00e6;
  --bg-button-green-hover: #9c0;
  --bg-button-blue: #0099cce6;
  --bg-button-blue-hover: #0099cc;
  --bg-button-none: none;
  --bg-button-disabled: #949494;

  --common-shadow-color: #dadada;

  --common-transition: all 0.2s ease-in;
}

.text {
  font-size: 22pt;
  line-height: 52px;
}

.text_color_dark {
  color: var(--text-color-dark);
}

.text_color_light {
  color: var(--text-color-light);
}

.text_type_button {
  font-size: 14pt;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  padding: 0;
}