.form {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    margin: 0 auto;
    align-items: center;
    gap: 12px;
    padding: 24px;
}

.title {
    padding: 0;
    margin: 0;
}

.button {
    padding: 0;
    margin: 0 auto;
}