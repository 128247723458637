.main {
  z-index: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  margin-top: -88px;
  overflow-x: hidden;
}

.main__banner {
  width: 100%;
  /*height: 100vh;*/
  height: 86px;
}

.main__banner-back {
  position: fixed;
  top: 0;
  z-index: -1;
  height: 100vh;
  width: 100%;
  background-image: url('../../images/sub.jpg');
  background-size: cover;
  background-position: center;
  background-color: #222;
}

.main__banner-content {
  /*perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;*/
  /*    perspective-origin-x: 100%; */

  /*display: flex;*/
  display: none;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  max-width: 1080px;
  padding: 24px 32px;
  margin: 0 auto;
  height: 100%;
}

.main__banner-image {
  height: 100%;
  max-height: 520px;
  background-image: url('../../images/rocket.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
}

.main__banner-caption {
  max-width: 540px;
  z-index: 999;
  flex: 1;
}

.main__banner-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  margin: 100vh 0;
  transform: translateZ(0) scale(1);
  transform-style: preserve-3d;
  width: 100%;
  background-color: #f5f6f7;
  height: 45vh;
}

.main__banner-title {
  display: inline;
  color: #fff;
  font-size: 44pt;
  font-weight: 500;
  line-height: 64px;
  margin: 0;
}

.main__wave {
  display: none;
  background: linear-gradient(to right, rgb(255, 255, 255), chocolate);
  height: 0px;
  position: relative;
  transform: rotate(180deg);
}

.main__wave::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  right: 0;
  background-repeat: repeat;
  height: 10px;
  background-size: 20px 20px;
  background-image: radial-gradient(circle at 10px -5px, #f5f6f7 12px, transparent 13px);
}

.main__block {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}

.main__block-double {
  position: relative;
  max-width: 1080px;
  display: flex;
  flex-wrap: wrap;
  margin: -128px auto -144px auto;
}

.main__block-light {
  background-color: #f5f6f7;
}

.main__block-overlayed {
  padding-bottom: 104px;
}

.main__block-semioverlayed {
  padding-bottom: 52px;
}

.main__block-cards {
  position: relative;
  max-width: 1200px;
  width: 100%;
  border-radius: 24px;
  z-index: 1;
  background-attachment: fixed;
  /*background-color: #f5f6f7;*/
  /*background-image: url('../../images/y.jpg');*/
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  overflow: visible;
  /*padding: 24px 0 56px 0;*/
  /*    background: -webkit-linear-gradient(180deg, rgb(245, 245, 245), rgb(199, 234, 246));
    background: -moz-linear-gradient(180deg, rgb(245, 245, 245), rgb(199, 234, 246));
    background: linear-gradient(180deg, rgb(245, 245, 245), rgb(199, 234, 246));*/
}

.main__block-cards::after {
  content: '';
  /*background-color: #1117;*/
  /*background-color: #09c7;*/
  position: absolute;
  max-width: 1200px;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  /*backdrop-filter: blur(8px);*/
}

.main__block-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  z-index: 0;
  background-image: url('../../images/p.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*background-attachment: fixed;*/
}

.main__block-dark {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 24px;
  background-color: #f5f6f7;
  /*background-image: url('../../images/f.jpg');*/
  z-index: 1;
  margin: -16px 0;
}

.main__block-dark::after {
  content: '';
  /*background-color: #02020212;*/
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  /*backdrop-filter: blur(24px);*/
}


.main__block-circle {
  position: absolute;
  z-index: 0;
  border-radius: 50%;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  /*background-color: #09c;*/
  background-color: #fff1;
  width: 144px !important;
  height: 144px !important;
  transition: .2s;
  z-index: 999;
  /*backdrop-filter: blur(8px);*/
}

@keyframes MOVE-CIRCLE-ONE {
  from {
    transform: translateY(10%) translateX(0);
  }

  to {
    transform: translateY(10%) translateX(0);
  }

  50% {
    transform: translateY(-20%) translateX(10%);
    left: 30%;
    top: 10%;
  }
}

@keyframes MOVE-CIRCLE-TWO {
  from {
    transform: translateY(0) translateX(0);
  }

  to {
    transform: translateY(0) translateX(0);
  }

  50% {
    transform: translateY(20%) translateX(-70%);
    right: 50%;
    top: 50%;
  }
}

@keyframes MOVE-CIRCLE-THREE {
  from {
    transform: translateY(0) translateX(-10%);
  }

  to {
    transform: translateY(0) translateX(-10%);
  }

  50% {
    transform: translateY(-20%) translateX(-40%);
    right: 20%;
    bottom: -20%;
  }
}

@keyframes MOVE-CIRCLE-FOUR {
  from {
    transform: translateY(0) translateX(-10%);
  }

  to {
    transform: translateY(0) translateX(-10%);
  }

  50% {
    transform: translateY(-20%) translateX(70%);
    left: 30%;
    bottom: 10%;
  }
}

.main__block-circle-one {
  animation-name: MOVE-CIRCLE-ONE;
  /*background-color: #09c;*/
  left: 32px;
  top: 32px;
  width: 320px;
  height: 320px;
  animation-duration: 34s;
}

.main__block-circle-two {
  animation-name: MOVE-CIRCLE-TWO;
  /*background-color: #9c0;*/
  right: 48px;
  top: 48px;
  width: 80px;
  height: 80px;
  animation-duration: 38s;
}

.main__block-circle-three {
  animation-name: MOVE-CIRCLE-THREE;
  /*background-color: #c09;*/
  right: 24px;
  bottom: 24px;
  width: 240px;
  height: 240px;
  animation-duration: 32s;
}

.main__block-circle-four {
  animation-name: MOVE-CIRCLE-FOUR;
  /*background-color: #c90;*/
  left: 32px;
  bottom: 32px;
  width: 100px;
  height: 100px;
  animation-duration: 30s;
}

.main__content {
  width: 100%;
  max-width: 1080px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 56px 24px;
  /*   padding: 8px 32px;
    border-radius: 12px;
    background-color: #fffc;
    backdrop-filter: blur(24px);
    box-shadow: 0 -1px 1px #fffe;
    color: #323232;*/
}

.main__secondary {
  position: relative;
  border-radius: 32px;
  /*max-width: 1080px;*/
  width: 100%;
  z-index: 0;
  /*background-color: #02020220;*/
  /*background-color: #ffffff08;*/
  /*background-color: #09c7;*/
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  /*backdrop-filter: blur(8px);*/
  background-attachment: fixed;
  background-image: url('../../images/st.jpg');
  /*box-shadow: 0 -1px 1px #fff5;*/
  /*box-shadow: 0 -1px 1px #3335;*/
  text-align: center;
  /*padding: 48px 48px 24px 48px;*/
  transition: .3s;
  /*transition-property: margin, box-shadow;*/
  /*filter: grayscale();*/
  display: flex;
}

.main__appear {
  margin-top: 0px;
  margin-bottom: 0px;
}


.main__disappear {
  /*transform: perspective(480px) rotateX( 16deg );*/
  /*transform-style: preserve-3d;*/
  margin-top: 96px;
  margin-bottom: 96px;
}

.main__secondary::after {
  content: '';
  background-color: #02020212;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 24px;
}

.main__secondary:hover {
  /*transform: perspective(480px) rotateX( 2deg );*/
  /*transform-style: preserve-3d;*/
  box-shadow: 0 8px 24px #7777;
  margin-top: -8px;
  margin-bottom: 8px;
  /*max-width: 100%;*/
}

.main__block:hover>.main__block-circle {
  /*background-color: #fc0;*/
}


.main__secondary-button {
  /*    border: 2px solid #fff;
    display: inline-block;
    color: #fff;
    padding: 8px 12px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 13pt;
    margin: 8px 0 0px 0;*/
  cursor: pointer;
  transition: .2s;
  text-decoration: none;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 8px;
  display: inline-block;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
}

.main__secondary-button:hover {
  background-color: #fff;
  color: #121212;
}

.main__bg {
  position: absolute;
  width: 100%;
  height: 200%;
  left: 0;
  top: 0;
  z-index: -1;
  animation-name: MOVE-BG;
  background-size: cover;
  background-position: top;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  background-image: url('../../images/rb.jpg');
  background-size: cover;
  background-position: top;
  opacity: 0;
}

@keyframes MOVE-BG {
  from {
    transform: translateY(-5%) translateX(0);
    height: 200%;
  }

  to {
    transform: translateY(-5%) translateX(0);
    height: 200%;
  }

  50% {
    transform: translateY(-30%) translateX(-10%);
    height: 320%;
    width: 120%;
  }
}

.main__navlink {
  text-decoration: none;
}

.main__secondary-text {
  display: block;
  max-width: 1080px;
  font-size: 22pt;
  line-height: 52px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}

.main__secondary-highlight {
  /*border-radius: 8px;
    background-color: #0099cc;
    padding: 0 8px;*/
  font-weight: 500;
}

.main__content-text {
  font-size: 22pt;
  line-height: 52px;
  display: inline;
  color: #323232;
  display: inline-block;
  margin: 0;
}

.main__block-column {
  margin: 0;
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 320px;
  transition: .2s;
}

.main__block-column2 {
  margin: 0;
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: .2s;
  min-width: 320px;
  z-index: 2;
}


.main__feedback {
  background-color: #fff;
  padding: 24px 40px;
  margin: 0;
  border-radius: 24px;
  transition: .4s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*box-shadow: 0 1px 16px #7771;*/
  backdrop-filter: blur(24px);
  z-index: 2;
}

.main__bro {
  border-radius: 0 24px 24px 0;
  margin: 80px 0 80px 0;
  background-color: #fffd;
  flex: 1;
  z-index: 1;
  /*transform: perspective(240px) rotateY( -1deg );*/
  /*transform-style: preserve-3d;*/
}

.main__map {
  flex: 1;
  min-height: 200px;
  border-radius: 12px;
  margin: 16px 0 24px 0;
  padding: 0;
  border: none;
}

.main__content-center {
  text-align: center;
}

.main__content-caption {
  font-weight: 500;
  font-size: 20pt;
  padding-bottom: 16px;
  margin: 0;
  line-height: 36px;
}

.main__content-double {
  line-height: 36px;
  font-size: 22px;
}

.main__content-contacts {
  line-height: 28px;
  font-size: 22px;
}

.main__content-highlight {
  border-radius: 8px;
  background-color: #ffb85032;
  padding: 0 8px;
}

.main__content-annotation {
  opacity: 0.8;
  font-size: 14px;
  font-weight: 500;
}

.main__cards {
  flex: 1;
  display: flex;
  gap: 56px;
}


.main__cards-link {
  flex: 1;
  text-decoration: none;
  color: inherit;
}

.main__card {
  transition: .3s;
  flex: 1;
  background-color: #0099ff;
  color: #fff;
  border-radius: 12px;
  min-height: 320px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.main__card:hover {
  box-shadow: 0 8px 48px #7777;
}

.main__card:hover>.main__card-image {
  height: 210px;
}

.main__card-title {
  font-size: 20pt;
  font-weight: 500;
  margin: 0;
  padding-bottom: 16px;
  color: #09c;
  opacity: .64;
}

.main__minicard-image {
  transition: .3s;
  transition-timing-function: ease-out;
  right: 0;
  position: absolute;
  object-fit: contain;
  object-position: right;
  height: 108px;
  margin-right: -16px;
  margin-top: -56px;
  opacity: .64;
  filter: grayscale();
}

.main__card-image {
  transition: .4s;
  object-fit: contain;
  object-position: right;
  height: 180px;
  margin-right: -56px;
  margin-top: -64px;
}

.main__card-text {
  flex: 1;
  font-size: 14pt;
  font-weight: 400;
  line-height: 28px;
  margin: 12px 0;
}

.main__minicards {
  position: relative;
  width: 100%;
  max-width: 1080px;
  padding: 0;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 72px;
  min-height: 320px;
  padding-bottom: 24px;
}

.main__minicard {
  position: relative;
  transition: .2s;
  transition-timing-function: ease-out;
  min-width: 240px;
  flex: 1;
  /*min-height: 320px;*/
  background-color: #fff9;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: visible;
}

.main__minicard:hover {
  overflow: visible;
  background-color: #fffc;
  /*backdrop-filter: blur(24px);*/
  box-shadow: 0 12px 32px #dadada77;
  margin-top: -8px;
  margin-bottom: 8px;
  /*transform: perspective(480px) rotateY( -2deg );
        transform-style: preserve-3d;*/
}

.main__minicard:hover>.main__minicard-image {
  opacity: 1;
  height: 128px;
  margin-right: -24px;
  margin-top: -72px;
  filter: none;
}

.main__logos {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0 24px 0;
  justify-content: center;
  gap: 64px;
  width: 100%;
}

.main__logo-container {
  min-width: 96px;
  min-height: 52px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(0) invert(1);
}

.main__logo-ts {
  background-image: url('../../images/ts.png');
}

.main__logo-osq {
  background-image: url('../../images/osq.png');
}

.main__logo-mk {
  background-image: url('../../images/mk.png');
}

.main__logo-cmg {
  background-image: url('../../images/cmg.png');
}

.main__logo-ub {
  background-image: url('../../images/ub.svg');
}

.main__logo-gdc {
  background-image: url('../../images/gdc.png');
}

@media (max-width: 876px) {
  .main__bro {
    border-radius: 24px;
    margin: 24px 0;
  }

  .main__block-cards {
    padding: 0;
  }

  .main__block-back {
    border-radius: 0;

  }

  .main__block-bottom {
    padding: 0;
  }

  .main__block-double {
    margin: -80px auto 32px auto;
    padding: 16px;
    gap: 16px;
  }

  .main__banner-title {
    font-size: 30pt;
    line-height: 44px;
  }

  .main__secondary {
    background-attachment: scroll;
    background-position: center;
  }

  .main__block-back {
    background-attachment: scroll;
    background-position: top;
  }
}


@media (max-width: 562px) {
  .main__banner-image {
    display: none;
  }
}
