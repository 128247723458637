.b24__form-container {
  width: 100%;
  max-width: 1080px;
  box-sizing: border-box;
  margin: 0 auto;
  /*   padding: 8px 32px;
  border-radius: 12px;
  background-color: #fffc;
  backdrop-filter: blur(24px);
  box-shadow: 0 -1px 1px #fffe;
  color: #323232;*/
  background-color: #fff;
  padding: 24px 40px;
  border-radius: 24px;
  transition: .4s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*box-shadow: 0 1px 16px #7771;*/
  backdrop-filter: blur(24px);
  z-index: 2;
}

.b24__content-highlight {
  border-radius: 8px;
  background-color: #ffb85032;
  padding: 0 8px;
}

.b24__form {
  margin: 0 auto;
}

.b24__content-caption {
  margin: 0 auto;
  padding-bottom: 6px;
}


.b24__content-text {
  margin: 0 auto;
  max-width: 700px;
  text-align: center;
}

.b24__form .b24-form-inner-box {
  background-color: #fff important!;
}
