.headerLink {
    
}

.headerLink__navlink {
    text-decoration: none;
    color: #fffd;
    cursor: pointer;
    transition: .2s;
    display: inline;
}

    .headerLink__navlink:hover {
        /*color: #09c;*/
        /*color: #f5e7d3;*/
        /*color: #a9e3fb;*/
        color: #fff;
    }

.headerLink__navlink-highlighted {
    border-radius: 12px;
    background-color: #ffffff16;
    padding: 2px 12px;
}

    .headerLink__navlink-highlighted:hover {
        background-color: #09c;
        color: #fff;
    }

.headerLink__navlink-hovered {
    padding-bottom: 24px;
}

.headerLink__card {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 8px;
    padding: 12px 16px;
    border-radius: 12px;
    opacity: 0;
    pointer-events: none;
    transition: .2s;
    transition-property: opacity, margin-top;
    transition-timing-function: ease-in;
    background-color: #fff;
    overflow: hidden;
    /*backdrop-filter: blur(24px);*/
    box-shadow: 0 -1px 1px #fff7;
}

.headerLink__card-visible {
    margin-top: 16px;
    pointer-events: all;
    opacity: 1;
}


.headerLink__card-navlink {
    margin: 6px 0;
    color: #121212;
}

    .headerLink__card-navlink:hover {
        color: #09c;
    }