.blog-page {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    margin-top: -88px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.blog-page__banner {
    position: relative;
    width: 100%;
    padding: 80px 0 48px 0;
    background-size: cover;
    background-position: center;
    background-color: rgb(0, 44, 139);
    background-attachment: fixed;
    z-index: 0;
}

    .blog-page__banner::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: #02020222;
        z-index: -1;
    }

.blog-page__banner-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1000px;
    box-sizing: border-box;
    padding: 24px;
    margin: 0 auto;
}

.blog-page__banner-image {
    display: none;
    flex: 1;
    height: 80%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.blog-page__banner-caption {
    text-align: center;
    flex: 1;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.blog-page__banner-subtitle {
    font-size: 18pt;
    font-weight: 400;
    line-height: 36px;
    margin: 0;
}

.blog-page__banner-title {
    font-size: 40pt;
    font-weight: 400;
    line-height: 56px;
    margin: 0;
}

.blog-page__wave {
    background: linear-gradient(to right, rgb(255, 255, 255), chocolate);
    height: 0px;
    position: relative;
    transform: rotate(180deg);
}

    .blog-page__wave::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -10px;
        right: 0;
        background-repeat: repeat;
        height: 10px;
        background-size: 20px 20px;
        background-image: radial-gradient(circle at 10px -5px, #f2f2f2 12px, transparent 13px);
    }

.blog-page__content {
    align-items: center;
    width: 100%;
    max-width: 1000px;
    border-radius: 24px 24px 0 0;
    box-sizing: border-box;
    padding: 12px 24px 48px 24px;
    margin: -32px auto 0 auto;
    font-size: 14pt;
    line-height: 32px;
    position: relative;
}

.blog-page__content__record {
    background-color: #fff;
    padding: 12px 36px 80px 36px;
    box-shadow: 0 12px 32px #dadada77;
    flex: 1;
}

@media (max-width: 876px) {
    .blog-page__banner-title {
        font-size: 42pt;
        line-height: 64px;
    }
}
