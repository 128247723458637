.record-card {
    position: relative;
    transition: .2s;
    transition-timing-function: ease-out;
    min-width: 240px;
    flex: 1;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: visible;
    box-sizing: border-box;
}

.record-card:hover {
    overflow: visible;
    background-color: #fff;
    box-shadow: 0 12px 32px #dadada77;
    margin-top: -8px;
    margin-bottom: 8px;
}


.record-card__title {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding: 20px 32px 4px 32px;
    color: #222;
    opacity: 1;
}

.record-card__image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
}

.record-card__text {
    flex: 1;
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
    padding: 4px 32px 16px 32px;
    margin: 0;
    color: #222;
}

.record-card__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 32px 8px 32px;
}

.record-card__link {
    flex: 1;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0;
    color: #09c;
}

.record-card__date {
    flex: 1;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0;
    text-align: right;
    color: #aaa;
}
