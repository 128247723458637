@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&family=Orelega+One&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.b24-form-state-container {
  border-radius: 10px;
}

.b24-form-state-container .b24-form-loader {
  border-radius: 10px;
  font-family: 'Jost' !important;
  background-color: #fff !important;
  outline: #ffffff solid 3px !important;
}

.b24-form-state-container .b24-form-success {
  border-radius: 10px;
  font-family: 'Jost' !important;
  background-color: #fff !important;
  outline: #ffffff solid 3px !important;
}

.b24-form-state-container .b24-form-success .b24-form-state-inner {
  border-radius: 10px;
}

.b24-form-state-container .b24-form-success .b24-form-inner-box {
  border-radius: 10px;
}

.b24-form-wrapper {
    margin-top: 48px !important;
    background: none !important;
}

.b24-form-header {
    display: none !important;
}

.b24-form-control {
    font-family: 'Jost' !important;
    border-radius: 12px !important;
    background-color: #fff !important;
    font-size: 14pt !important;
    height: 52px !important;
}

.b24-form-control:focus {
    border: 2px solid #09c !important;
}

.b24-form-control-label {
    font-family: 'Jost' !important;
    font-weight: 500 !important;
}

.b24-form-field-agreement-link, .b24-form-control-add-btn {
    font-family: 'Jost' !important;
}

.b24-form-control:focus + .b24-form-control-label {
    color: #09c !important;
    opacity: 1 !important;
}

.b24-form-btn {
    font-family: 'Jost' !important;
    border-radius: 12px !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 17px !important;
}

.b24-form-state-text {
    font-family: 'Jost' !important;
}

.b24-form-padding-side {
    padding: 0 !important;
}

.b24-form-sign {
    display: none !important;
}

.wrapper {
    /* The height needs to be set to a fixed value for the effect to work.
   * 100vh is the full height of the viewport. */
    height: 100vh;
    /* The scaling of the images would add a horizontal scrollbar, so disable x overflow. */
    overflow-x: hidden;
    /* Enable scrolling on the page. */
    overflow-y: auto;
    /* Set the perspective to 2px. This is essentailly the simulated distance from the viewport to transformed objects.*/
    perspective: 2px;
}

.section {
    /* Needed for children to be absolutely positioned relative to the parent. */
    position: relative;
    /* The height of the container. Must be set, but it doesn't really matter what the value is. */
    height: 100vh;
    /* For text formatting. */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-shadow: 0 0 5px #000;
}

.parallax::after {
    /* Display and position the pseudo-element */
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* Move the pseudo-element back away from the camera,
   * then scale it back up to fill the viewport.
   * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
    transform: translateZ(-1px) scale(1.5);
    /* Force the background image to fill the whole element. */
    background-size: 100%;
    /* Keep the image from overlapping sibling elements. */
    z-index: -1;
}

/* The styling for the static div. */
.static {
    background: red;
}

/* Sets the actual background images to adorable kitties. This part is crucial. */
.bg1::after {
    background-image: url("https://placekitten.com/g/900/700");
}

.bg2::after {
    background-image: url("https://placekitten.com/g/800/600");
}

.cdx-simple-image {
}

    .cdx-simple-image .cdx-loader {
        min-height: 200px;
    }

    .cdx-simple-image .cdx-input {
        margin-top: 10px;
    }

    .cdx-simple-image img {
        max-width: 100%;
        vertical-align: bottom;
    }

.cdx-simple-image__caption[contentEditable=true][data-placeholder]:empty::before {
    position: absolute;
    content: attr(data-placeholder);
    color: #707684;
    font-weight: normal;
    opacity: 0;
}

.cdx-simple-image__caption[contentEditable=true][data-placeholder]:empty::before {
    opacity: 1;
}

.cdx-simple-image__caption[contentEditable=true][data-placeholder]:empty:focus::before {
    opacity: 0;
}


.cdx-simple-image__picture--with-background {
    background: #eff2f5;
    padding: 10px;
}

    .cdx-simple-image__picture--with-background img {
        display: block;
        max-width: 60%;
        margin: 0 auto;
    }

.cdx-simple-image__picture--with-border {
    border: 1px solid #e8e8eb;
    padding: 1px;
}

.cdx-simple-image__picture--stretched img {
    max-width: none;
    width: 100%;
}
