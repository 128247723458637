.minicard {
    position: relative;
    transition: .2s;
    transition-timing-function: ease-out;
    min-width: 240px;
    flex: 1;
    background-color: #fff9;
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: visible;
}

    .minicard:hover {
        overflow: visible;
        background-color: #fffc;
        box-shadow: 0 12px 32px #dadada77;
        margin-top: -8px;
        margin-bottom: 8px;
    }

        .minicard:hover > .minicard__image {
            opacity: 1;
            height: 128px;
            margin-right: -24px;
            margin-top: -72px;
            filter: none;
        }


.minicard__title {
    font-size: 20pt;
    font-weight: 500;
    margin: 0;
    padding-bottom: 16px;
    color: #09c;
    opacity: .64;
}

.minicard__image {
    transition: .3s;
    transition-timing-function: ease-out;
    right: 0;
    position: absolute;
    object-fit: contain;
    object-position: right;
    height: 108px;
    margin-right: -16px;
    margin-top: -56px;
    opacity: .64;
    filter: grayscale();
}

.minicard__text {
    flex: 1;
    font-size: 14pt;
    font-weight: 400;
    line-height: 28px;
    margin: 12px 0;
}
