.calcRow {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #fff7;
    padding: 12px 24px;
    border-top: 1px solid #ddd7;
}

.calcRow:first-child {
    border-radius: 24px 24px 0 0;
    border-top: none;
}


.calcRow:last-child {
    border-radius: 0 0 24px 24px;
}

.selected {
    background-color: #ffb85012;
}

.content {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
}

.info {
    display: flex;
    align-items: center;
    gap: 16px;
}

.id {
    width: 36px;
    opacity: 0.6;
}

.caption {
    flex: 1;
}

.name {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    max-width: 560px;
    display: inline;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.sortIcon {
    width: 18px;
    height: 18px;
    margin-left: 4px;
    margin-bottom: -3px;
    transition: .3s;
    opacity: .4;
}

.sortIconExpanded {
    transform: rotate(-180deg);
}

.about {
    max-width: 560px;
    font-size: 15px;
    line-height: 24px;
    opacity: 0.8;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: .4s;
}

.aboutExpanded {
    padding-top: 8px;
    max-height: 600px;
    opacity: 0.8;
}

.timeIcon {
    width: 18px;
    height: 18px;
    opacity: .52;
}

.interval {
    width: 128px;
    line-height: 24px;
    display: flex;
    gap: 6px;
    align-items: center;
    opacity: .52;
}

.price {
    width: 200px;
    text-align: right;
    line-height: 28px;
}

.selector {
    width: 32px;
    display: flex;
    justify-content: flex-end;
}

.total {
    font-weight: 500;
    font-size: 18px;
}

.cb {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px solid #09c;
}

.cbInner {
    flex: 1;
    background-color: #09c;
    border-radius: 5px;
}

@media (max-width: 876px) {
    .calcRow {
        gap: 10px;
        padding: 8px 16px;
    }

    .id {
        width: 20px;
        font-size: 16px;
    }

    .content {
        flex-direction: column;
        gap: 8px;
        order: 0;
    }

    .content {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .caption {
        gap: 0;
        width: 100%;
    }

    .info {
        gap: 8px;
        width: 100%;
        justify-content: space-between;
    }

    .name {
        font-size: 15px;
        line-height: 24px;
    }

    .sortIcon {
        width: 16px;
        height: 16px;
    }

    .interval {
        width: auto;
        min-width: 80px;
        text-align: left;
        font-size: 16px;
        line-height: 24px;
    }

    .price {
        width: auto;
        font-size: 16px;
        line-height: 24px;
    }

    .priceText {
        background-color: #ffb85032;
        padding: 0;
        color: #222;
        display: inline;
        box-shadow: 6px 0 0 #ffb85032, -6px 0 0 #ffb85032;
    }

}
